import React from 'react';
import { useNavigate } from 'react-router-dom';
import './PhotoGallery.css';

import gathering from './gathering.JPG'; // Correctly importing the Thanksgiving image
import food from './food.jpg';

const PhotoGallery = () => {
  const navigate = useNavigate();

  const galleryImages = [
    { src: gathering, title: 'Gathering 2024', route: '/gathering' }, // Using the imported image path
    { src: food, title: 'Fellowship 2024', route: '/Fellowship' }, // Using the imported image path
    // Add more event images as necessary
  ];

  const handleImageClick = (route) => {
    navigate(route);
  };

  return (
    <div className="photo-gallery-container">
      <h1>Photo Gallery</h1>

      <div className="gallery-grid">
        {galleryImages.map((image, index) => (
          <div key={index} className="gallery-item" onClick={() => handleImageClick(image.route)}>
            <img src={image.src} alt={image.title} />
            <p>{image.title}</p>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoGallery;
