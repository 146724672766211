import React from 'react';
import './ChurchIntroduction.css';

// Import image
import church from './church.jpg';


function AboutPage() {
  return (
    <div className="church-introduction">
      <div className="church-image-container">
        <img src={church} alt="Church" className="church-image" />
      </div>
      <div className="church-text">
        <h1>플로리다 말씀교회</h1>
       <p>따뜻하고 부드럽고 온유하고 감미로움이 있는 교회<br />
          기도와 찬양소리, 말씀 읽는 소리, 웃음이 있는 교회<br />
          관용과 용서와 양보와 감싸줌과 배려가 있는 교회<br />
          높은 사람도, 낮은 사람도, 소외된 사람도 없는 교회<br />
          밝고 따뜻하고 진솔한 믿음의 향기가 나는 교회<br />
          생명을 사랑하고 노약자를 보듬고 사랑하는 교회<br />
          성령 충만, 주님의 임재, 주의 만져주심이 있는 교회<br />
          자연스럽고 인간적이고 순수하고 정직한 교회<br />
          지역사회를 향해 활짝 열려 있는 교회<br />
          플로리다 말씀교회가 지향하는 교회입니다.


          <br />
          <br />
          "수고하고 무거운 짐진 자들아 다 내게로 오라 내가 너희를 쉬게 하리라" <br />
          (마태복음 11:28)</p>
      </div>
    </div>
  );
}

export default AboutPage;