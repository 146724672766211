import React from 'react';
import './MissionsPage.css';

// Import the image
import missions from './missions.jpg';

function MissionsPage() {
  return (
    <div className="missions-container">
      <div className="missions-image-container">
        <img src={missions} alt="Missions" className="missions-image" />
      </div>
      <div className="missions-text">
        <p>
          어떤 만남이든지 만남에는 그 만남에 대한 '기대'가 선행되는 경우가 많습니다. 그 만남과 교제를 
          가능케 하신 십자가의 존귀하신 사랑보다 내가 만나는 사람을 통한 내 인생의 유불리를 따지게 되는 만남이 많습니다. 
          나와 만나며 교제하는 사람들이 내 기대와 꿈에 따라줄 것을 요구하고 그 기대와 꿈이 현실화 
          되지 않을 경우 쉽게 분노하고 좌절하며 만남을 파탄으로 몰고 갑니다. 만남과 교제에 대한 기대와 
          꿈이 성도의 교제를 통해 드러나야 하는 십자가보다 앞서기 때문입니다. 그래서 늘 긴장하고 
          있는 삶, 늘 경계를 해체하지 않는 만남이 심지어 교회 안에서 까지 발생하고 있음이 현실입니다. 
          하지만 플로리다 말씀교회는 다릅니다. 다르고자 하는 교회입니다. 지치고 상한 영혼들이 평안히
          쉴만한 마르지 않는 영혼의 쉼터로 탬파지역 한인들과 함께 하길 기대합니다. 우리와 함께 하지 않으시렵니까? 
          세상에서 누릴수 없는, 세상이 줄 수도 없는 주님의 평안과 안식을 누리게 되실 줄 믿습니다.
        </p>
        <h2>플로리다 말씀교회 <br />
          담임목사 김기석
        </h2>
      </div>
    </div>
  );
}

export default MissionsPage;
