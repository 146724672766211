import React from 'react';
import './NewsPage.css'; // Link to the CSS

// Import the candles image
import candles from './candles.jpg';

const announcements = [
  { id: 1, date: '2024-06-02', title: '소식' },
  { id: 2, date: '2024-06-09', title: '소식' },
  { id: 3, date: '2024-06-16', title: '소식' },
  { id: 4, date: '2024-06-23', title: '소식' },
  { id: 5, date: '2024-06-30', title: '소식' },
  { id: 6, date: '2024-07-07', title: '소식' },
  { id: 7, date: '2024-07-14', title: '소식' },
  { id: 8, date: '2024-07-21', title: '소식' },
  { id: 9, date: '2024-07-28', title: '소식' },
  { id: 10, date: '2024-08-04', title: '소식' },
  { id: 11, date: '2024-08-11', title: '소식' },
  { id: 12, date: '2024-08-18', title: '소식' },
  { id: 13, date: '2024-08-25', title: '소식' },
  { id: 14, date: '2024-09-1', title: '소식' },
  { id: 15, date: '2024-09-8', title: '소식' },
  { id: 16, date: '2024-09-15', title: '소식' }

];

function NewsPage() {
  return (
    <div className="news-page">
      <div className="header-image-container">
        <img src={candles} alt="Candles" className="header-image" />
        <div className="header-text">
          <h1>교회소식</h1>
          <p>Announcements</p>
        </div>
      </div>

      <table className="news-table">
        <thead>
          <tr>
            <th>No</th>
            <th>제목</th>
            <th>게시일</th>
          </tr>
        </thead>
        <tbody>
          {announcements
            .slice()
            .reverse()
            .map((announcement, index) => (
              <tr key={announcement.id}>
                <td>{announcements.length - index}</td>
                <td>
                  <a href={`/announcement/${announcement.id}`} className="announcement-link">
                    {announcement.title}
                  </a>
                </td>
                <td>{announcement.date}</td>
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
}

export default NewsPage;
