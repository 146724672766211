import React from 'react';
import './YouTubePage.css';

function YouTubePage() {
  return (
    <div className="youtube-page">
      <h1>
        <img
          src="https://upload.wikimedia.org/wikipedia/commons/b/b8/YouTube_Logo_2017.svg"
          alt="YouTube"
          className="youtube-logo" // Apply the class here
        />
      </h1>
      <div className="video-grid">
        {/* First Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/0fvCKHBjhu4"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-(셋째, 풍랑 가운데 함께 하시는 하나님) 그는 구원을 베푸실 전능자시라 <br /><span className="eng-text">(9/15/2024)</span>
          </p>
        </div>

        {/* Second Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/3rp_Oxz25EY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회-(둘째, 풍랑 가운데 함께 하시는 하나님) 내가 함께할 것이라 <br /><span className="eng-text">(9/8/2024)</span>
          </p>
        </div>

        {/* Third Video */}
        <div className="video-item">
          <iframe
            width="100%"
            height="315"
            src="https://www.youtube.com/embed/GS2ohGeNREY"
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
          <p>
          플로리다 말씀교회 -(첫째, 풍랑 가운데 함께 하시는 하나님) 너희 믿음이 어디 있느냐? <br /><span className="eng-text">(9/1/2024)</span>
          </p>
        </div>
      </div>
    </div>
  );
}

export default YouTubePage;
